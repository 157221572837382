import React, { Component } from "react";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import CustomTableCell from "./TableCell";

const styles = {
    centerAlign: {
        textAlign: "center"
    },
    rowHeight: {
        height: 56
    },
    rowHeight2: {
        height: 32
    }
};

class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, classes, orderBy, columnData, columnData2, actions } = this.props;

        return (
            <TableHead>
                <TableRow className={classes.rowHeight} style={{borderBottom: columnData2 && "2px solid rgb(51, 51, 51)"}}>
                    {actions && (
                        <CustomTableCell key={"actions"} sortDirection={false} style={{ width: "auto" }}>
                            <Typography>{"Actions"}</Typography>
                        </CustomTableCell>
                    )}
                    {columnData.map(column => {
                        return (
                            <CustomTableCell
                                key={column.id}
                                colSpan={column.colspan || 1}
                                sortDirection={orderBy === column.id ? order : false}
                                style={{ borderRight: column.lineRight && "2px solid white", width: column.width ? column.width : "auto" }}
                                className={column.center ? classes.centerAlign : ""}>
                                {column.allowSort && (
                                    <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.createSortHandler(column.id)}>
                                            <Typography>{column.label}</Typography>
                                        </TableSortLabel>
                                    </Tooltip>
                                )}
                                {!column.allowSort && <Typography>{column.label}</Typography>}
                            </CustomTableCell>
                        );
                    }, this)}
                </TableRow>
                {columnData2 && (
                    <TableRow className={classes.rowHeight2}>
                        {columnData2.map(column => {
                            return (
                                <CustomTableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    style={{ borderRight: column.lineRight && "2px solid white", width: column.width ? column.width : "auto" }}
                                    className={column.center ? classes.centerAlign : ""}>
                                    <Typography>{column.label}</Typography>
                                </CustomTableCell>
                            );
                        }, this)}
                    </TableRow>
                )}
            </TableHead>
        );
    }
}

export default withStyles(styles)(EnhancedTableHead);
